































import { invoiceAllNegativeBalances } from "@/api/balance-service";
import handleError from "@/helpers/errors";
import store from "@/store/store";
import { defineComponent, reactive } from "@vue/composition-api";

export default defineComponent({
  name: "InvoiceAllNegativeBalances",
  setup() {
    const vm = reactive({
      loading: false,
    });

    async function submit(): Promise<void> {
      vm.loading = true;
      try {
        await invoiceAllNegativeBalances();
        await store.dispatch("displayAlert", {
          color: "green",
          message: "Suksess!",
        });
      }
      catch (e) {
        handleError(e);
        await store.dispatch("displayAlert", {
          color: "error",
          message: "Noe gikk galt!"
        });
      }
      vm.loading = false;
    }

    return {
      submit,
      vm,
    };
  }
});
