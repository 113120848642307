import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VToolbar,{staticClass:"mb-6",staticStyle:{"z-index":"100","position":"sticky","top":"20px"},attrs:{"flat":""}},[_c(VSpacer),_c('h2',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.total)+" bestillinger ")]),_c(VBtn,{staticStyle:{"margin-left":"20px"},attrs:{"dark":"","color":"primary","loading":_vm.isLoading},on:{"click":function($event){return _vm.forceSearch()}}},[_c(VIcon,[_vm._v("mdi-refresh")]),_vm._v(" Search ")],1),_c(VSpacer)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"3"}},[_c(VMenu,{ref:"periodMenu",attrs:{"close-on-content-click":false,"return-value":_vm.periodString,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.periodString=$event},"update:return-value":function($event){_vm.periodString=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"outlined":"","dense":"","label":"Periode","prepend-icon":"mdi-calendar","readonly":"","clearable":""},on:{"click:clear":function($event){return _vm.clearPeriod()}},model:{value:(_vm.periodString),callback:function ($$v) {_vm.periodString=$$v},expression:"periodString"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.vm.periodMenu),callback:function ($$v) {_vm.$set(_vm.vm, "periodMenu", $$v)},expression:"vm.periodMenu"}},[_c(VDatePicker,{attrs:{"no-title":"","scrollable":"","range":""},model:{value:(_vm.filters.period),callback:function ($$v) {_vm.$set(_vm.filters, "period", $$v)},expression:"filters.period"}})],1)],1),_c(VCol,{attrs:{"cols":"3"}},[_c(VSelect,{attrs:{"outlined":"","dense":"","items":_vm.vm.items,"label":"Status","clearable":""},model:{value:(_vm.filters.status),callback:function ($$v) {_vm.$set(_vm.filters, "status", $$v)},expression:"filters.status"}})],1),_c(VCol,{attrs:{"cols":"3"}},[_c('course-picker',{attrs:{"multiple":""},model:{value:(_vm.filters.courseIDs),callback:function ($$v) {_vm.$set(_vm.filters, "courseIDs", $$v)},expression:"filters.courseIDs"}})],1),_c(VCol,{attrs:{"cols":"3"}},[_c(VMenu,{ref:"startTimeRangeMenu",attrs:{"close-on-content-click":false,"return-value":_vm.startRangeString,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.startRangeString=$event},"update:return-value":function($event){_vm.startRangeString=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"outlined":"","dense":"","label":"Oppstart","prepend-icon":"mdi-calendar","readonly":"","clearable":""},on:{"click:clear":function($event){return _vm.clearStart()}},model:{value:(_vm.startRangeString),callback:function ($$v) {_vm.startRangeString=$$v},expression:"startRangeString"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.vm.startTimeRangeMenu),callback:function ($$v) {_vm.$set(_vm.vm, "startTimeRangeMenu", $$v)},expression:"vm.startTimeRangeMenu"}},[_c(VDatePicker,{attrs:{"no-title":"","scrollable":"","range":""},model:{value:(_vm.filters.startTimeRange),callback:function ($$v) {_vm.$set(_vm.filters, "startTimeRange", $$v)},expression:"filters.startTimeRange"}})],1)],1)],1),_vm._l((_vm.orders),function(order,index){return _c('order-card',{key:order.ID,staticClass:"mb-2",attrs:{"order":order,"index":index}})}),_c('div',{staticStyle:{"width":"100%","display":"flex","justify-content":"center"}},[(_vm.orders.length)?_c(VBtn,{attrs:{"color":"primary","loading":_vm.isLoading},on:{"click":function($event){return _vm.nextPage()}}},[_vm._v(" Flere result ")]):_vm._e()],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }