




































import { changeOrder } from "@/api/order-service";
import CoursePicker from "@/components/courses/CoursePicker.vue";
import store from "@/store/store";
import Learnlink from "@learnlink/interfaces";
import { defineComponent, inject, onMounted, PropType, reactive, ref, watchEffect } from "@vue/composition-api";

export default defineComponent({
  name: "ChangeOrderDialog",
  components: { CoursePicker },
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    order: {
      type: Object as PropType<Learnlink.Order.View.Admin.Thumbnail>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const _value = ref(props.value);
    watchEffect(() => {
      emit("input", _value);
    });
    const updateOrder = inject<(uid: string, order: Partial<Learnlink.Order.View.Admin.Thumbnail>|null) => void>("updateOrder");

    const vm = reactive({
      loading: false,
      courseID: "",
    });

    onMounted(async() => {
      vm.courseID = props.order.courseID;
    });

    async function update() {
      vm.loading = true;
      try {
        const response = await changeOrder({
          ID: props.order.ID,
          courseID: vm.courseID,
          invoiceItemID: props.order.invoiceItemID,
        });
        updateOrder && await updateOrder(props.order.ID, response);
        await store.dispatch("displayAlert", {
          color: "success",
          message: "Oppdatert! 🥳",
        });
        _value.value = false;
      }
      catch (e) {
        await store.dispatch("displayAlert", {
          color: "error",
          message: e,
        });
      }
      vm.loading = false;
    }

    return {
      _value,
      update,
      vm,
    };
  },
});
