




































import { getOrdersForCustomer } from "@/api/order-service";
import OrderCard from "@/components/orders/OrderCard.vue";
import store from "@/store/store";
import { defineComponent, provide, reactive, ref } from "@vue/composition-api";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "SearchOrders",
  components: { OrderCard },
  setup() {
    const vm = reactive({
      loading: false,
      query: "",
    });

    const orders = ref<Learnlink.Order.View.Admin.Thumbnail[]>([]);

    provide("updateOrder", (uid: string, order: Partial<Learnlink.Order.View.Admin.Thumbnail> | null) => {
      if (!orders) return;

      if (order) {
        const index = orders.value.findIndex((o) => o.ID === uid);
        if (index !== undefined && index !== -1) {
          const clone = [...orders.value];
          clone[index] = { ...orders.value[index], ...order };
          orders.value = clone;
        }
      }
      else {
        const index = orders.value.findIndex((o) => o.ID === uid);
        if (index !== undefined && index !== -1) {
          orders.value.splice(index, 1);
        }
      }
    });

    async function search() {
      vm.loading = true;
      try {
        orders.value = await getOrdersForCustomer(vm.query);
        if (!orders.value.length) {
          await store.dispatch("displayAlert", {
            color: "error",
            message: "Fant ingen bestillinger under denne UID-en.",
          });
        }
      }
      catch (e) {
        await store.dispatch("displayAlert", {
          color: "error",
          message: e,
        });
      }
      vm.loading = false;
    }

    return {
      search,
      orders,
      vm,
    };
  }
});
