import { axios } from "@/configuration";
import Learnlink from "@learnlink/interfaces";

const ENDPOINT = "/admin/orders";

export async function getOrders({
  page,
  courseIDs,
  period,
  startRange,
  status,
}: {
  page: number,
  courseIDs: string[],
  status: string,
  startRange: [number, number] | number[],
  period: [number, number] | number[],
}): Promise<{orders: Learnlink.Order.View.Admin.Thumbnail[], total: number}> {
  const url = `${ENDPOINT}/all`;
  const urlParms = new URLSearchParams();

  urlParms.append("page", page.toString());
  courseIDs.forEach((value) => urlParms.append("courseID", value));
  if (status.length) urlParms.append("status", status);

  if (startRange[0] && startRange[1]) {
    urlParms.append("started-between-1", startRange[0].toString());
    urlParms.append("started-between-2", startRange[1].toString());
  }

  if (period[0] && period[1]) {
    urlParms.append("period-between-1", period[0].toString());
    urlParms.append("period-between-2", period[1].toString());
  }

  const authRes = await axios.get<{
    orders: Learnlink.Order.View.Admin.Thumbnail[],
    total: number,
  }>(url + "?" + urlParms.toString());
  return authRes.data;
}

export async function getUnhandledOrders(): Promise<Learnlink.Order.View.Admin.Thumbnail[]> {
  const authRes = await axios.get<Learnlink.Order.View.Admin.Thumbnail[]>(
    `${ENDPOINT}/unhandled`,
  );
  return authRes.data;
}

export async function getOrdersForCustomer(uid: string): Promise<Learnlink.Order.View.Admin.Thumbnail[]> {
  const authRes = await axios.get<Learnlink.Order.View.Admin.Thumbnail[]>(
    `${ENDPOINT}/${uid}`,
  );
  return authRes.data;
}

export async function createOrder(order: Learnlink.Order.Create): Promise<Learnlink.Order.Order> {
  const authRes = await axios.post<Learnlink.Order.Order>(
    ENDPOINT, order
  );
  return authRes.data;
}

export async function updateOrder(order: Learnlink.Order.Update): Promise<Learnlink.Order.Order> {
  const authRes = await axios.put<Learnlink.Order.Order>(
    `${ENDPOINT}/${order.ID}`, order
  );
  return authRes.data;
}

export async function changeOrder(order: Learnlink.Order.Update): Promise<Learnlink.Order.Order> {
  const authRes = await axios.put<Learnlink.Order.Order>(
    `${ENDPOINT}/changeOrder`, order
  );
  return authRes.data;
}

export async function cancelOrder(orderID: string, invoiceItemID: string):Promise<void> {
  if (invoiceItemID) {
    await axios.delete<void>(
      `${ENDPOINT}/${orderID}/${invoiceItemID}`,
    );
  }
  else {
    await axios.delete<void>(
      `${ENDPOINT}/${orderID}`,
    );
  }
}

export async function createInvoiceItem(orderID: string, uid: string, courseID: string, courseTitle: string): Promise<Learnlink.Order.Order> {
  const orderRes = await axios.post<Learnlink.Order.Order>(
    `${ENDPOINT}/invoiceItem`, {
      uid,
      courseID,
      courseTitle,
      orderID,
    }
  );
  return orderRes.data;
}
