import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,{attrs:{"justify":"center"}},[_c(VCard,{staticClass:"py-6 px-12 ma-8",attrs:{"width":"600"}},[_c('div',{staticClass:"text-center mb-4"},[_c('h2',[_vm._v(" Ny bestilling ")])]),_c('course-picker',{model:{value:(_vm.vm.courseID),callback:function ($$v) {_vm.$set(_vm.vm, "courseID", $$v)},expression:"vm.courseID"}}),_c(VAutocomplete,{attrs:{"outlined":"","dense":"","disabled":!_vm.vm.courseID || _vm.vm.loadingTeams,"loading":_vm.vm.loadingTeams,"items":_vm.vm.availableTeams,"label":"Team (valgfritt)"},model:{value:(_vm.vm.teamID),callback:function ($$v) {_vm.$set(_vm.vm, "teamID", $$v)},expression:"vm.teamID"}}),_c('br'),_c(VTextField,{attrs:{"label":"kunde-UID","outlined":""},model:{value:(_vm.vm.customerUID),callback:function ($$v) {_vm.$set(_vm.vm, "customerUID", $$v)},expression:"vm.customerUID"}}),_c('div',{staticClass:"text-center"},[_c(VBtn,{attrs:{"color":"primary","disabled":!_vm.vm.customerUID || !_vm.vm.courseID,"loading":_vm.vm.loading},on:{"click":function($event){return _vm.create()}}},[_vm._v(" Opprett ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }