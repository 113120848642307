



































































































































import { getOrders } from "@/api/order-service";
import CoursePicker from "@/components/courses/CoursePicker.vue";
import OrderCard from "@/components/orders/OrderCard.vue";
import { stringTimeToUnix } from "@/utils/timeUtils";
import { Ref, computed, defineComponent, onMounted, provide, reactive, ref, watch } from "@vue/composition-api";
import Learnlink from "@learnlink/interfaces";
import store from "@/store/store";

export default defineComponent({
  name: "AllOrders",
  components: { CoursePicker, OrderCard },
  setup() {
    const offsetPage = ref(0);
    const filters = reactive({
      period: [] as string[],
      startTimeRange: [] as string[],
      status: "",
      courseIDs: [] as string[]
    });
    const vm = reactive({
      periodMenu: false,
      startTimeRangeMenu: false,
      items: [
        { value: "paid", text: "Betalt" },
        { value: "unpaid", text: "Ikke betalt" },
        { value: "past due", text: "Forfalt" },
        { value: "cancelled", text: "Kansellert" },
        { value: "no invoiceItem", text: "Ikke fakturert" },
        { value: "unknown status", text: "Ukjent status" },
      ],
    });
    const periodString = computed(() => filters.period.join(" - "));
    const startRangeString = computed(() => filters.startTimeRange.join(" - "));

    const ordersPages: Ref<Record<string, Learnlink.Order.View.Admin.Thumbnail[]>> = ref({});
    const orders: Ref<(Learnlink.Order.View.Admin.Thumbnail)[]> = computed(() => {
      const orders: (Learnlink.Order.View.Admin.Thumbnail)[] = [];
      for (const page in ordersPages.value) {
        for (const order of ordersPages.value[page]) {
          orders.push(order);
        }
      }
      return orders;
    });

    const isLoading = ref(false);
    const total = ref(0);
    const currentPromise: Ref<Promise<unknown> | null> = ref(null);
    let requestID = 0;

    const search = async () => {
      isLoading.value = true;

      requestID = Math.random();
      const reference = requestID;

      await new Promise((resolve) => setTimeout(resolve, 100));
      if (requestID !== reference) return;
      const promises: Promise<void>[] = [];

      for (let i = 0; i <= offsetPage.value; i++) {
        if (!ordersPages.value[i.toString()]) {
          ordersPages.value[i.toString()] = [] as Learnlink.Order.View.Admin.Thumbnail[];

          if (filters.status === "unknown status" && filters.courseIDs.length) {
            store.dispatch("displayAlert", {
              color: "red",
              message: "Filter 'Ukjent status' og en eller flere valg av kurs er utillgjenlig pga firebase limits"
            });
            continue;
          }

          promises.push(
            getOrders({
              courseIDs: filters.courseIDs,
              startRange: filters.startTimeRange.map((value) => stringTimeToUnix(value, "00:00")),
              page: offsetPage.value ?? 0,
              period: filters.period.map((value) => stringTimeToUnix(value, "00:00")),
              status: filters.status ?? "",
            }).then((value) => {
              if (requestID !== reference) return;

              if (total.value !== value.total) {
                total.value = value.total;
              }
              ordersPages.value = {
                ...ordersPages.value,
                [i.toString()]: value.orders,
              };
            })
          );
        }
      }
      if (promises.length) {
        currentPromise.value = Promise.allSettled(promises).then((values) => {
          const rejected = values.filter((value): value is PromiseRejectedResult => value.status === "rejected");

          if (rejected.length) {
            store.dispatch("displayAlert", {
              color: "red",
              title: "Noe gikk galt.",
              message: rejected.map((value) => String(value.reason)).join(", ")
            });
          }
        })
          .finally(() => {
            isLoading.value = reference !== requestID;
          });
      }
      else {
        isLoading.value = false;
      }
    };

    const forceSearch = () => {
      ordersPages.value = {};
      offsetPage.value = 0;
      search();
    };
    const nextPage = () => {
      offsetPage.value += 1;
    };

    onMounted(forceSearch);
    watch(filters, forceSearch);
    watch(offsetPage, search);

    provide("updateOrder", (uid: string, order: Partial<Learnlink.Order.View.Admin.Thumbnail> | null) => {
      if (!orders.value) return;

      if (order) {
        const index = orders.value.findIndex((o) => o.ID === uid);
        if (index !== undefined && index !== -1) {
          const clone = [...orders.value];
          clone[index] = { ...orders.value[index], ...order };
          orders.value = clone;
        }
      }
      else {
        const index = orders.value.findIndex((o) => o.ID === uid);
        if (index !== undefined && index !== -1) {
          orders.value.splice(index, 1);
        }
      }
    });

    return {
      total,
      isLoading,
      vm,
      forceSearch,
      nextPage,
      orders,
      offsetPage,
      filters,
      periodString,
      startRangeString,
      clearPeriod: () => {
        filters.period = [];
      },
      clearStart: () => {
        filters.startTimeRange = [];
      }
    };
  },
});
