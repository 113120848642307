









































import { getUnhandledOrders } from "@/api/order-service";
import OrderCard from "@/components/orders/OrderCard.vue";
import useAsyncData from "@/helpers/useAsyncData";
import Learnlink from "@learnlink/interfaces";
import { defineComponent, provide, reactive } from "@vue/composition-api";

export default defineComponent({
  name: "NewOrders",
  components: { OrderCard },
  setup() {
    const { data: orders, isLoading, refresh } = useAsyncData(() => getUnhandledOrders());
    const vm = reactive({
      loading: false,
    });

    provide("updateOrder", (uid: string, order: Partial<Learnlink.Order.View.Admin.Thumbnail> | null) => {
      if (!orders.value) return;

      if (order) {
        const index = orders.value.findIndex((o) => o.ID === uid);
        if (index !== undefined && index !== -1) {
          const clone = [...orders.value];
          clone[index] = { ...orders.value[index], ...order };
          orders.value = clone;
        }
      }
      else {
        const index = orders.value.findIndex((o) => o.ID === uid);
        if (index !== undefined && index !== -1) {
          orders.value.splice(index, 1);
        }
      }
    });

    async function mount(): Promise<void> {
      vm.loading = true;
      await refresh();
      vm.loading = false;
    }

    return {
      isLoading,
      mount,
      orders,
      vm,
    };
  }
});
