import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[(!_vm.isLoading)?_c(VToolbar,{staticStyle:{"z-index":"100","position":"sticky","top":"20px"},attrs:{"flat":""}},[_c(VSpacer),_c('h2',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.orders.length)+" nye bestillinger ")]),_c(VBtn,{attrs:{"x-large":"","icon":"","loading":_vm.vm.loading},on:{"click":function($event){return _vm.mount()}}},[_c(VIcon,[_vm._v("mdi-refresh")])],1),_c(VSpacer)],1):_vm._e(),(_vm.isLoading)?_c('div',{staticClass:"text-center my-16"},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._l((_vm.orders),function(order){return _c('order-card',{key:order.ID,staticClass:"mb-2",attrs:{"order":order}})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }