










































import AllOrders from "@/components/orders/AllOrders.vue";
import CreateOrder from "@/components/orders/CreateOrder.vue";
import InvoiceAllNegativeBalances from "@/components/orders/InvoiceAllNegativeBalances.vue";
import NewOrders from "@/components/orders/NewOrders.vue";
import SearchOrders from "@/components/orders/SearchOrders.vue";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "Orders",
  components: { InvoiceAllNegativeBalances, SearchOrders, CreateOrder, AllOrders, NewOrders },
  metaInfo() {
    return {
      title: "Admin / Bestillinger",
    };
  },
});
