













































import { createOrder } from "@/api/order-service";
import { listAvailableTeamsForCourse } from "@/api/team-service";
import CoursePicker from "@/components/courses/CoursePicker.vue";
import handleError from "@/helpers/errors";
import Moment from "@/services/moment";
import store from "@/store/store";
import Learnlink from "@learnlink/interfaces";
import { defineComponent, reactive, watch } from "@vue/composition-api";

export default defineComponent({
  name: "CreateOrder",
  components: { CoursePicker },

  setup() {
    const vm = reactive({
      availableTeams: [] as { value: string, text: string }[],
      courseID: "",
      customerUID: "",
      loading: false,
      loadingTeams: false,
      teamID: "",
    });

    watch(() => vm.courseID, () => {
      if (vm.courseID) getAvailableTeams(vm.courseID);
    });

    async function create(): Promise<void> {
      vm.loading = true;
      if (!vm.customerUID) {
        store.dispatch("displayAlert", {
          color: "error",
          message: "Kunde-UID mangler! 😡",
        });
      }
      else if (!vm.courseID) {
        store.dispatch("displayAlert", {
          color: "error",
          message: "kurs-ID mangler! 🤬",
        });
      }
      else {
        try {
          await createOrder({
            customerUID: vm.customerUID,
            courseID: vm.courseID,
            teamID: vm.teamID || undefined,
          });
          store.dispatch("displayAlert", {
            color: "success",
            message: "Opprettet! 🥳",
          });
          vm.customerUID = "";
          vm.courseID = "";
          vm.teamID = "";
        }
        catch (e) {
          store.dispatch("displayAlert", {
            color: "error",
            message: "Noe gikk galt! 🤯\n\n" + e,
          });
        }
      }
      vm.loading = false;
    }

    function getTextValuePairForAvailableTeam(team: Learnlink.Team.View.Admin.Thumbnail): { text: string, value: string } {
      const dayAndTime = Moment.unix(team.teamSessions[0]?.startTime || 0).format("dddd HH:mm");
      return {
        text: `${team.title} – ${team.location} ${dayAndTime} – (${team.parents.length}/${team.size} foreldre) – ${team.birthYears.join(", ")}`,
        value: team.ID,
      };
    }

    function sortTeamByDayAndTime(a: Learnlink.Team.View.Admin.Thumbnail, b: Learnlink.Team.View.Admin.Thumbnail): number {
      const aDay = Moment.unix(a.teamSessions[0]?.startTime || 0).isoWeekday();
      const bDay = Moment.unix(b.teamSessions[0]?.startTime || 0).isoWeekday();
      const aTime = Moment.unix(a.teamSessions[0]?.startTime || 0).format("HHmm");
      const bTime = Moment.unix(b.teamSessions[0]?.startTime || 0).format("HHmm");

      return aDay - bDay || +aTime - +bTime;
    }

    async function getAvailableTeams(courseID: string) {
      vm.loadingTeams = true;
      try {
        const availableTeams = await listAvailableTeamsForCourse(courseID);
        vm.availableTeams = availableTeams
          .sort(sortTeamByDayAndTime)
          .map(getTextValuePairForAvailableTeam);
      }
      catch (e) {
        handleError(e);
      }
      vm.loadingTeams = false;
    }

    return {
      create,
      vm,
    };
  },
});
